// dev
// export const isDevelopment = true
// export const baseApi = 'https://db-dev.108.jobs/client-api'
// export const urlImageUrl =
//   'https://lab-108-bucket.s3-ap-southeast-1.amazonaws.com/'
// export const httpsUrl = 'https://db-dev.108.jobs'
// export const Base_Urls = 'https://dev.108.jobs/'
// export const Base_Url = 'https://dev.108.jobs'
// export const seekerUrl = 'https://seeker-dev.108.jobs'
// export const seekerInformation = '/seeker-info-for-web'
// export const employerUrl = 'https://employer-dev.108.jobs/'

// production
export const isDevelopment = false
export const baseApi = 'https://db.108.jobs/client-api'
export const urlImageUrl = 'https://lab-108-bucket.s3-ap-southeast-1.amazonaws.com/'
export const httpsUrl = 'https://db.108.jobs'
export const Base_Urls = 'https://108.jobs/'
export const Base_Url = 'https://108.jobs'
export const seekerUrl = 'https://seeker.108.jobs'
export const seekerInformation = '/seeker-info-for-web'
export const employerUrl = 'https://employer.108.jobs/'


