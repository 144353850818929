<template>
  <nav>
    <div class="container">
      <div class="navbar">
        <div class="burger" @click="clickBurgerToggle">
          <i class="fa-solid fa-bars"></i>
        </div>
        <div class="navbar-start">
          <div class="navbar-brand">
            <NuxtLink to="/">
              <img
                src="https://lab-108-bucket.s3.ap-southeast-1.amazonaws.com/assistant-logo/108.Jobs-Logo.svg"
              />
            </NuxtLink>
          </div>
          <!-- language switcher  -->
          <div class="language-switcher">
            <div
              class="lang-item"
              @click="dropdrowToggleLang = !dropdrowToggleLang"
            >
              <i class="fa-regular fa-globe"></i>
              <p v-if="laoStatus">{{ $t('laoLang') }}</p>
              <p v-if="enStatus">{{ $t('enLang') }}</p>
            </div>
            <!-- dropdown -->
            <ul class="dropdrown" v-if="dropdrowToggleLang">
              <li v-if="enStatus" @click="selectLang('la')">ພາສາລາວ</li>
              <li v-else @click="selectLang('en')">English</li>
            </ul>
          </div>
        </div>
        <div class="navbar-end">
          <div class="navbar-item-list">
            <NuxtLink activeClass="active-link" class="hoverable" to="/">{{
              $t('home')
            }}</NuxtLink>
            <NuxtLink activeClass="active-link" class="hoverable" to="/108-cv"
              >108CV</NuxtLink
            >
            <NuxtLink
              activeClass="active-link"
              class="hoverable"
              to="/job-search"
              >{{ $t('job search') }}</NuxtLink
            >
            <NuxtLink
              activeClass="active-link"
              class="hoverable"
              :to="{ path: '/company-search', query: { tab: 'AllCompanies' } }"
              >{{ $t('company search') }}</NuxtLink
            >
            <NuxtLink
              activeClass="active-link"
              class="hoverable"
              to="/article"
              >{{ $t('article') }}</NuxtLink
            >
            <a href="#" class="has-dropdown" v-if="!isAuth">
              <button
                class="button primary is-rounded"
                @click="SignInToggle = !SignInToggle"
              >
                {{ $t('login') }}
              </button>
              <!-- dropdrow -->
              <ul class="dropdown" v-if="SignInToggle" ref="target">
                <li>
                  <NuxtLink
                    to="/auth/login"
                    @click="SignInToggle = !SignInToggle"
                  >
                    <i class="fa-solid fa-sign-in"></i>
                    {{ $t('login') }}
                  </NuxtLink>
                </li>
                <li>
                  <a @click="RegisterPage('test')">
                    <i class="fa-solid fa-user-pen"></i>{{ $t('register') }}</a
                  >
                </li>
              </ul>
            </a>
            <client-only>
              <a href="#" class="has-dropdown" v-if="isAuth">
                <div class="user" @click="SignInToggle = !SignInToggle">
                  <i class="fas fa-user" v-if="!user?.profile"></i>
                  <img v-if="user?.profile" :src="user.profile" alt="profile" />
                  <!-- {{ userData.profile }} -->
                </div>
                <!-- dropdrow -->
                <ul class="dropdown" v-if="SignInToggle" ref="target">
                  <li @click="profile('/my-profile')">
                    <span><i class="fal fa-user"></i></span>
                    {{ $t('my profile') }}
                  </li>
                  <li @click="profile('/myjobs')">
                    <span><i class="fal fa-suitcase"></i></span>
                    {{ $t('my job') }}
                  </li>
                  <li @click="profile('/job-alert')">
                    <span><i class="fal fa-bell"></i></span>
                    {{ $t('my job alert') }}
                  </li>
                  <li>
                    <div class="line"></div>
                  </li>
                  <li @click="logout">
                    <span><i class="fal fa-sign-out-alt"></i></span>
                    {{ $t('logout') }}
                  </li>
                </ul>
              </a>
            </client-only>
            <span class="divider"></span>
            <a class="employer-btn" @click="employer()">{{
              $t('employerLink')
            }}</a>
          </div>
        </div>

        <!-- mobile navbar  -->
        <div class="mobile-navbar" v-if="burgerToggle">
          <div class="navbar-bg" @click="clickBurgerToggle"></div>
          <div class="navbar-container">
            <div class="navbar-header">
              <div class="bg"></div>
              <span v-if="!isAuth">{{ $t('have not login') }}</span>
              <a v-else :href="'/download-app'">{{ $t('my profile') }}</a>
              <div
                class="user"
                :style="{ 'background-image': 'url(' + user?.profile + ')' }"
              ></div>
            </div>
            <ul class="navbar-list">
              <li>
                <Nuxt-link
                  class="navbar-item"
                  to="/"
                  @click="clickBurgerToggle"
                >
                  <i class="fa-solid fa-house"></i>
                  <p>{{ $t('home') }}</p>
                </Nuxt-link>
              </li>
              <li>
                <Nuxt-link
                  class="navbar-item"
                  to="/108-cv"
                  @click="clickBurgerToggle"
                >
                  <i class="fa-solid fa-file-pdf"></i>
                  <p>108CV</p>
                </Nuxt-link>
              </li>
              <li>
                <Nuxt-link
                  class="navbar-item"
                  to="/job-search"
                  @click="clickBurgerToggle"
                >
                  <i class="fa-regular fa-magnifying-glass"></i>
                  <p>{{ $t('job search') }}</p>
                </Nuxt-link>
              </li>
              <li>
                <Nuxt-link
                  to="/all-company"
                  @click="clickBurgerToggle"
                  class="navbar-item"
                >
                  <i class="fa-solid fa-building"></i>
                  <p>{{ $t('company search') }}</p>
                </Nuxt-link>
              </li>
              <li>
                <Nuxt-link
                  to="/article"
                  @click="clickBurgerToggle"
                  class="navbar-item"
                >
                  <i class="fa-solid fa-newspaper"></i>
                  <p>{{ $t('article') }}</p>
                </Nuxt-link>
              </li>
              <li>
                <Nuxt-link
                  v-if="!isAuth"
                  to="/auth/login"
                  @click="clickBurgerToggle"
                  class="navbar-item"
                >
                  <i class="fa-solid fa-sign-in"></i>
                  <p>{{ $t('login') }}</p>
                </Nuxt-link>
              </li>
              <li>
                <Nuxt-link
                  v-if="!isAuth"
                  @click="RegisterPage"
                  class="navbar-item"
                >
                  <i class="fa-solid fa-user-pen"></i>
                  <p>{{ $t('register') }}</p>
                </Nuxt-link>
              </li>
              <li>
                <a v-if="isAuth" @click="logout" class="navbar-item">
                  <i class="fa-solid fa-right-from-bracket"></i>
                  <p>{{ $t('logout') }}</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- login modal  -->
    <div v-if="Auth.isLoginModal">
      <LoginModal :isLoginModal="Auth.isLoginModal" />
    </div>
  </nav>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@/store/auth'
import { useUserStore } from '@/store/user'
import LoginModal from '../modal/login.vue'
import { useCookieUtils } from '~/composables/useCookieUtils'
const useCookies = useCookieUtils('token')
const Auth = useAuthStore()
const User = useUserStore()
const { userData, fetchUserInfo, userLevel } = User
const employerURL: any = useNuxtApp().$employer
const seekerURL: any = useNuxtApp().$seekerURL
const router = useRouter()
const avatar =
  'https://lab-108-bucket.s3.ap-southeast-1.amazonaws.com/assistant-logo/profile-logo.jpeg'
const target = ref<any>(false)
onClickOutside(target, () => (SignInToggle.value = false))
const SignInToggle = ref(false)

const isAuth = computed(() => {
  // console.log('check auth', Auth.isAuth)
  return Auth.isAuth
})
// language setup switcher
const enStatus = ref<any>(false)
const laoStatus = ref<any>(false)
const useCookiesLang: any = useCookieUtils('lang')
const i18n = useI18n()

i18n.locale.value = useCookiesLang.value || 'en'
enStatus.value = useCookiesLang.value === 'en'
laoStatus.value = useCookiesLang.value === 'la'
if (!enStatus.value && !laoStatus.value) enStatus.value = true
const selectedLang = ref('')
const dropdrowToggleLang = ref(false)

// toggle
const burgerToggle = ref(false)
const clickBurgerToggle = () => {
  burgerToggle.value = !burgerToggle.value
}

const employer = () => {
  window.location.href = employerURL
}
// get seeker profile
const user = computed(() => User.userData)

//setup user fetch
onMounted(async () => {
  await User.fetchUserInfo()
})

const profile = (url?: any) => {
  SignInToggle.value = false
  let isURL
  if (!url) {
    isURL = seekerURL
  } else {
    isURL = seekerURL + url
  }
  if (typeof window !== 'undefined') {
    window.location.href = isURL
  }
}
const RegisterPage = (test: any) => {
  burgerToggle.value = false
  window.location.href = '/auth/register'
  // router.push('/auth/register')
  // alert(test)
}
const logout = async () => {
  try {
    burgerToggle.value = false
    SignInToggle.value = false
    useCookies.value = ''
    await Auth.signOut()
  } catch (error) {
    console.error('Sign out error', error)
  }
}
//watch token to store when refresh page
watch(
  () => useCookies.value,
  (value) => {
    if (value) {
      Auth.token = value
    }
  },
  { immediate: true, deep: true }
)

watch(
  () => i18n.locale.value,
  (value) => {},
  { immediate: true, deep: true }
)
const selectLang = (key: string) => {
  dropdrowToggleLang.value = false
  i18n.locale.value = key
  useCookiesLang.value = key

  enStatus.value = key === 'en'
  laoStatus.value = key === 'la'
}
// fetch
</script>
<style lang="scss" scoped>
nav {
  background-color: #fff;
  border-bottom: 1px solid var(--border-color);
  .navbar {
    height: 4rem;
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    // burger icon
    .burger {
      position: absolute;
      right: 0;
      margin-right: 1rem;
      display: none;
      cursor: pointer;
      font-size: var(--md-font);

      @include mobile {
        display: block;
        padding: 5px 10px;
      }
    }

    .navbar-item-list {
      align-items: center;
      display: flex;
      align-items: center;
      gap: 20px;

      a.hoverable {
        font-size: 1rem;
        position: relative;
        overflow: hidden;
        line-height: 1.8;
        color: var(--dark-grey-color);
        transition: all ease-in-out 0.2s;
        text-transform: capitalize;

        &:hover::after {
          display: block !important;
        }

        &:hover {
          color: var(--primary-color);
        }
        &.active-link {
          color: var(--primary-color);
        }
        &.active-link::after {
          display: block !important;
        }

        &::after {
          content: '';
          display: none;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 2px;
          background-color: var(--primary-color);
          animation: slide 0.3s;

          @keyframes slide {
            from {
              transform: translate(-100px);
            }

            to {
              transform: translate(0);
            }
          }
        }
      }

      .users {
        &:hover {
          color: var(--font-color);
        }

        ul {
          position: absolute;
          right: 0;
          top: 55px;
          background-color: var(--light-grey-color);
          border: 1px solid var(--border-color);
          padding: 20px;
          width: 200px;
          border-radius: 10px;
          box-shadow: 0 0 10px 3px rgba(#000, 0.1);

          li {
            display: flex;
            padding: 10px 0 8px;

            &:hover {
              color: var(--primary-color);
            }

            span {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              text-align: center;
              margin-right: 5px;
            }

            .line {
              width: 100%;
              content: '';
              display: block;
              height: 1px;
              line-height: 0;
              background-color: var(--dark-grey-color);
              margin: 5px 0;
            }
          }
        }
        .user {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-position: center center;
          background-size: cover;
          background-origin: border-box;
          background-repeat: no-repeat;
          image-orientation: 0deg;
          border: 2px solid var(--border-color);
          overflow: hidden;
          position: relative;
        }
      }
      .employer-btn {
        font-size: 1rem;
        cursor: pointer;
        &:hover {
          color: var(--primary-color);
        }
      }
    }

    .navbar-start {
      display: flex;
      align-items: center;

      .navbar-brand {
        @include tablet {
          padding-left: 0.5rem;
        }
        @include mobile {
          padding-left: 0.5rem;
        }

        img {
          display: block;
          height: 2.2rem;
        }
      }
    }

    // navbar-start
    .navbar-end {
      display: flex;
      align-items: center;

      // @include tablet {
      //   display: none;
      // }
      @include mobile {
        display: none;
      }
    }
  }
}

// user
.has-dropdown {
  position: relative;
  &:hover {
    background-color: transparent;
  }
  .user {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--input-color);
    border-radius: 40px;
    overflow: hidden;
    img {
      object-fit: contain;
      aspect-ratio: 1/1;
    }
    i {
      color: var(--grey-color);
    }
  }

  // dropdrow
  .dropdown {
    width: 200px;
    position: absolute;
    z-index: 999;
    background-color: var(--bg-color);
    padding: 1.5rem;
    top: 3.25rem;
    right: 0;
    border-radius: 5px;
    display: grid;
    gap: 15px;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
    float: left;

    li {
      // text-transform: uppercase;
      display: flex;
      font-size: 1rem;
      transition: all ease-in-out 0.1s;

      i {
        margin-right: 10px;
        width: 20px;
        height: auto;
      }

      &:hover {
        color: var(--primary-color);
        // font-size: 16px;
      }
    }
  }
  .button {
    font-family: var(--font-family);
  }
}

// navbar mobile
.mobile-navbar {
  position: relative;

  @include desktop {
    display: none;
  }

  .navbar-bg {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
  }

  .navbar-container {
    position: fixed;
    right: 3.25rem;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 999;

    .navbar-header {
      position: relative;
      padding: 20px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--border-color);
      padding-bottom: 10px;
      span {
        font-size: var(--md-font);
        color: var(--dark-grey-color);
      }

      .user {
        width: 40px;
        height: 40px;
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        background-size: cover;
        cursor: pointer;
        background-color: var(--input-color);
        border-radius: 40px;

        i {
          color: var(--grey-color);
        }
      }
    }

    .navbar-list {
      display: flex;
      padding-top: 20px;
      flex-direction: column;
      padding: 20px;
      list-style: none;

      li {
        transition: all ease-in-out 0.1s;

        a.navbar-item {
          padding: 5px 0;
          cursor: pointer;
          display: flex;
          font-size: var(--md-font);
          color: var(--dark-grey-color);
          align-items: center;
          // border: 1px solid red;

          i {
            // border: 1px solid red;
            margin-right: 0.5rem;
            width: 30px;
            height: 30px;
            display: block;
            color: var(--input-color);
            display: grid;
            place-items: center;
          }

          &:hover {
            color: var(--primary-color);
          }
        }
        a.router-link-active {
          color: var(--primary-color);
          i {
            color: var(--primary-color);
          }
        }

        .langSwitchMB {
          display: flex;
          // align-items: end;
          justify-content: space-between;
          gap: 10px;
          margin: 0 auto;
          border: 1px solid var(--border-color);
          width: 130px;
          overflow: hidden;
          border-radius: 6px;
          span {
            width: 60px;
            padding: 5px 15px;
            cursor: pointer;
            // border: 1px solid red;
            transition: all 0.1s ease-in-out;

            &.checkedLang {
              background-color: var(--primary-color);
              color: #fff;
            }
          }
        }

        &:nth-child(8) {
          margin-top: 1rem;
        }
      }
    }
  }
}

// popup
.login {
  position: fixed;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;

  .pop-bg {
    background-color: rgba(0, 0, 0, 0.8);
    // cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .popup-content {
    position: relative;
    background-color: #fff;
    padding: 30px;
    border: 1px solid var(--border-color);
    width: 100%;
    max-width: 520px;
    border-radius: 4px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .close {
      position: absolute;
      top: 5px;
      left: 5px;
      cursor: pointer;
      display: grid;
      width: 30px;
      height: 30px;
      place-items: center;
      justify-content: center;
      padding: 0;
      opacity: 0.3;

      i {
        font-size: 25px;
        display: block;
      }

      &:hover {
        opacity: 1;
      }
    }

    h3 {
      margin-top: 30px;
      text-transform: uppercase;
      color: var(--primary-color);
      font-weight: 700;
      font-size: 20px;

      i {
        margin-right: 10px;
      }
    }

    p {
      font-size: 16px;
    }

    hr {
      background-color: var(--primary-color);
      width: 30px;
      display: block;
      height: 2px;
      margin: 1.5rem 0;
      border: none;
    }

    // field container
    .field-container {
      .btn-group {
        margin: 20px 0;
        text-align: center;

        button {
          width: 100%;
          text-transform: capitalize;
          margin-bottom: 10px;
        }

        span {
          color: var(--primary-color);
          cursor: pointer;
        }
      }

      // register
      .register {
        text-align: center;

        a {
          color: var(--primary-color);
        }
      }
    }
  }

  &.is-active {
    display: flex;
  }
}

.divider {
  content: '';
  width: 1px;
  height: 1rem;
  background-color: var(--border-color);
}

// language bar
.language-switcher {
  margin-left: 1rem;
  padding: 5px 8px;
  border-radius: 6px;
  background-color: var(--light-grey-color);
  cursor: pointer;
  transition: all 0.1s ease-out;
  display: flex;
  align-items: center;
  position: relative;
  color: var(--grey-color);

  .lang-item {
    font-size: 1rem;
    align-items: center;
    display: flex;
    font-size: var(--xsm-font);
    gap: 5px;
    user-select: none;

    i {
      display: grid;
      place-content: center;
    }
  }
  .dropdrown {
    font-size: var(--xsm-font);
    position: absolute;
    z-index: 999;
    top: 2.1rem;
    min-width: 100%;
    left: 0;
    background-color: var(--light-primary-color);
    color: var(--primary-color);
    border-radius: 6px;
    li {
      white-space: pre;
      padding: 0 8px;
      width: 100%;
      height: 2rem;
      display: flex;
      align-items: center;
      transition: all ease-in-out 0.1s;
      user-select: none;
      &:hover {
        color: var(--primary-color);
      }
    }
  }

  &:hover {
    background-color: var(--light-grey-color);
    .lang-item {
      color: var(--primary-color);
      i {
        color: var(--primary-color);
      }
    }
  }
}
</style>
