<template>
  <!-- <div ref="cookieDeclarationRef"></div> -->
  <div>
    <NuxtLayout>
      <SpeedInsights />
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { SpeedInsights } from '@vercel/speed-insights/nuxt'
const cookieDeclarationRef = ref<HTMLElement | null>(null)
const { cookieDeclaration } = useCookiebot()
const { consentBanner } = useCookiebot({})

onMounted(() => {
  consentBanner()
})
</script>

<style lang="scss">
@import url('~/assets/css/main.css');
@import url('~/assets/css/all.css');
@import url('~/assets/css/font.css');
@import url('~/assets/css/satoshi.css');
body.hidden {
  @include mobile {
    overflow: hidden;
  }
}

body,
html {
  width: 100%;
  height: 100%;
  font-size: var(--md-font);
  background-color: var(--bg-color);
  font-family: var(--font-family);
  line-height: 1.5;
  font-weight: 500;
  position: relative;
  box-sizing: border-box;
}

/*
$mobile-width: 768px;
$tablet-width: 769px;
$desktop-width: 1280px;
$widescreen-width: 1281px;
$fullhd-width: 1408px;
*/

.container {
  margin-left: auto;
  margin-right: auto;

  @include desktop {
    max-width: 1120px;
  }
  @include fullhd {
    max-width: 1281px;
  }
  @include mobile {
    padding: 0 1rem;
  }
}
</style>
